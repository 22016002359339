import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import PastSessions from '../../../components/StudentBookings/PastSessions';
import UpcomingSessions from '../../../components/StudentBookings/UpcomingSessions';
import GeneralSessions from '../../../components/StudentBookings/GeneralSessions';
import OneOnOneSessions from '../../../components/StudentBookings/OneOnOne';
import api from '../../../utils/auth';
import Swal from 'sweetalert2';
import Rating from 'react-rating-stars-component';

const MyBookings = () => {
  const [selectedTab, setSelectedTab] = useState('upcoming');
  const [unratedSession, setUnratedSession] = useState(null);

  useEffect(() => {
    const fetchUnratedPastSessions = async () => {
      try {
        const response = await api.get('/my-bookings/unrated-past-sessions');
        const { latestGeneralSession, latestOneOnOneSession } = response.data;



        // Set the latest unrated session to state
        if (latestGeneralSession) {
          setUnratedSession({ ...latestGeneralSession, sessionType: 'general' });
        } else if (latestOneOnOneSession) {
          setUnratedSession({ ...latestOneOnOneSession, sessionType: 'one-on-one' });
        }
      } catch (error) {
        console.error('Error fetching unrated past sessions:', error);
      }
    };

    fetchUnratedPastSessions();
  }, []);

  const handleSubmitRating = async (rating, message) => {
    try {
      const response = await api.post('/my-bookings/submit-rating', {
        sessionId: unratedSession._id,
        rating,
        message, // Include the message
        sessionType: unratedSession.sessionType,
      });
      setUnratedSession(null);
      Swal.fire('Thank you!', 'Your rating and message have been submitted.', 'success');
    } catch (error) {
      console.error('Error submitting rating:', error);
      Swal.fire('Error', 'Could not submit rating. Please try again later.', 'error');
    }
  };
  

  const RatingComponent = ({ onRatingChange, onMessageChange }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
      <Rating
        count={5}
        size={40} // Increase size for larger stars
        activeColor="#ffd700"
        onChange={onRatingChange}
      />
      <textarea
        placeholder="Optional message about the session..."
        style={{ 
          marginTop: '10px', 
          width: '100%', 
          maxWidth: '300px', 
          height: '80px',
          padding: '10px', // Add padding inside the textarea
          border: '1px solid #ccc', // Add a border
          borderRadius: '5px', // Add rounded corners
          boxSizing: 'border-box', // Include padding and border in the element's total width and height
          resize: 'none' // Prevent resizing of the textarea
        }}
        onChange={(e) => onMessageChange(e.target.value)}
      />
    </div>
  );
  


  // Trigger rating prompt
  // Trigger rating prompt with message
useEffect(() => {
  if (unratedSession) {
    Swal.fire({
      title: `Rate your session with ${unratedSession.instructorId.firstName} ${unratedSession.instructorId.lastName}`,
      html: `
        <p>Session: ${unratedSession.sessionName || 'One-on-One'} (${unratedSession.sessionType === 'general' ? 'General Session' : 'One-on-One Session'})</p>
        <div id="rating"></div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Submit Rating',
      cancelButtonText: 'Later',
      preConfirm: () => {
        const rating = document.getElementById('rating').getAttribute('data-rating');
        const message = document.getElementById('rating').getAttribute('data-message');
        if (!rating) {
          Swal.showValidationMessage('Please select a rating');
        }
        return { rating: parseInt(rating, 10), message };
      },
      didOpen: () => {
        const ratingElement = document.getElementById('rating');
        const root = createRoot(ratingElement);
        root.render(
          <RatingComponent
            onRatingChange={(newRating) => {
              ratingElement.setAttribute('data-rating', newRating);
            }}
            onMessageChange={(message) => {
              ratingElement.setAttribute('data-message', message);
            }}
          />
        );
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitRating(result.value.rating, result.value.message);
      }
    });
  }
}, [unratedSession]);


  return (
    <div className="max-w-[1400px] mx-auto p-5">
      <div className="text-2xl font-bold mb-5 text-primary-color">My Bookings</div>
      {/* Adjust the flex container for mobile view */}
      <div className="grid grid-cols-2 gap-2 lg:gap-0 md:flex md:flex-wrap md:justify-start mb-4">
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'upcoming'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('upcoming')}
        >
          Upcoming Sessions
        </button>
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'general'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('general')}
        >
          General Sessions
        </button>
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'oneonone'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('oneonone')}
        >
          One on One Sessions
        </button>
        <button
          className={`py-2.5 px-4 text-base font-medium ${
            selectedTab === 'past'
              ? 'text-primary-color border-b-2 border-primary-color'
              : 'text-gray-700 border-b border-border-color'
          }`}
          onClick={() => setSelectedTab('past')}
        >
          Past Sessions
        </button>
      </div>
      <div className="mt-6">
        {selectedTab === 'upcoming' && <UpcomingSessions />}
        {selectedTab === 'past' && <PastSessions />}
        {selectedTab === 'general' && <GeneralSessions />}
        {selectedTab === 'oneonone' && <OneOnOneSessions />}
      </div>
    </div>
  );
};

export default MyBookings;
