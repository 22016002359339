import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faUser,
  faCog,
  faSignOutAlt,
  faTimes,
  faHome,
  faUsers,
  faCalendarAlt,
  faBook,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { fetchCsrfToken } from '../../utils/csrf';
import api from '../../utils/auth';
import Notifications from '../Notifications';
import { db } from '../../firebase';
import { collection, query, onSnapshot, updateDoc, where } from 'firebase/firestore';
import InstructorSessionForm from '../InstructorSessionForm/InstructorSessionForm';
import UpcomingSessionsModal from '../UpcomingSessionsModal/UpcomingSessionsModal';
import { slide as Menu } from 'react-burger-menu';
import useInstructorStore from '../../store/useInstructorStore'; // Import Zustand instructor store
import './Header.css';

const Header = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notificationsVisible, setNotificationsVisible] = useState(false);
    const { instructorData, setInstructorData } = useInstructorStore(); // Zustand store
    const [unreadCount, setUnreadCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const notificationListenerRef = useRef(null);
    const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/defaultImage.png`;
    const logo = `${process.env.PUBLIC_URL}/assets/logos/Navigrated_logo.png`;

    const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

    const toggleNotifications = () => {
        if (!notificationsVisible && unreadCount > 0) {
            const notificationsQuery = query(
                collection(db, `users/${instructorData._id}/notifications`),
                where('read', '==', false)
            );
            onSnapshot(notificationsQuery, (snapshot) => {
                snapshot.docs.forEach((docSnapshot) => {
                    updateDoc(docSnapshot.ref, { read: true });
                });
                setUnreadCount(0);
            });
        }
        setNotificationsVisible(!notificationsVisible);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const csrfToken = await fetchCsrfToken();
                const response = await api.get(`/instructors/dashboard`, {
                    headers: {
                        'CSRF-Token': csrfToken,
                    },
                });


                // Store the instructor data in Zustand
                setInstructorData(response.data);

            } catch (error) {
                console.error('Error fetching instructor data:', error);
            }
        };
        fetchData();
    }, [setInstructorData]);

    useEffect(() => {
        if (instructorData?._id && !notificationListenerRef.current) {
            notificationListenerRef.current = onSnapshot(
                query(collection(db, `users/${instructorData._id}/notifications`), where('read', '==', false)),
                (snapshot) => setUnreadCount(snapshot.size)
            );
            return () => {
                if (notificationListenerRef.current) {
                    notificationListenerRef.current();
                }
            };
        }
    }, [instructorData?._id]);

    const handleAddSessionClick = () => {
        // Check if any critical profile fields are missing or incomplete
        const isProfileIncomplete =
          !instructorData.profilePicture ||
          !instructorData.firstName ||
          !instructorData.lastName ||
          !instructorData.email ||
          !instructorData.bio ||
          !instructorData.headline ||
          !instructorData.areaOfExpertise ||
          !instructorData.location ||
          !instructorData.timezone ||
          (instructorData.mentorship && 
            (!instructorData.mentorship.price || !instructorData.mentorship.availability)) ||
          !instructorData.education.length || // Assuming education is an array
          !instructorData.addedSkills.length || // Assuming addedSkills is an array
          !instructorData.experiences.length || // Assuming experiences is an array
          !instructorData.achievements.length || // Assuming achievements is an array
          !instructorData.payments.length; // Assuming payments is an array
      
        // If the profile is incomplete, show a SweetAlert and redirect to settings
        if (isProfileIncomplete) {
          swal({
            title: "Complete Your Profile",
            text: "To request a session, please complete your profile by heading to the settings page.",
            icon: "warning",
            buttons: {
              goToSettings: {
                text: "Go to Settings",
                value: true,
              },
              cancel: "Cancel",
            },
          }).then((value) => {
            if (value) {
              navigate('/mentor/settings/profile'); // Redirect to profile settings
            }
          });
        } else {
          // If the profile is complete, open the request session modal
          setIsModalOpen(true);
        }
      };
      

    const handleJoinSessionClick = () => {
        setIsJoinModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsJoinModalOpen(false);
    };

    const handleLogout = async () => {
        swal({
            title: "Are you sure?",
            text: "You will be logged out of your account!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willLogout) => {
            if (willLogout) {
                try {
                    const csrfToken = await fetchCsrfToken();
                    await api.post(`/auth/logout`, {
                        headers: {
                            'CSRF-Token': csrfToken,
                        },
                    });
                    navigate('/sign-in');
                } catch (error) {
                    console.error('Error during logout:', error);
                }
            }
        });
    };

    const handleItemClick = (path) => {
        navigate(path);
        setMenuOpen(false);
    };

    return (
        <header className="flex justify-between items-center h-20 w-full bg-gray-50 border-b border-border-color fixed top-0 z-50">
            <div className="flex items-center justify-center w-60 border-r border-border-color bg-white" style={{ width: 'clamp(160px, 20vw, 250px)' }}>
                <img src={logo} alt="Navigrated Logo" className="h-[79px] hidden lg:block" />
                <div className="block lg:hidden">
                    <Menu 
                        isOpen={menuOpen} 
                        onStateChange={(state) => setMenuOpen(state.isOpen)}
                    >
                        <a className={`menu-item ${navigate.pathname === '/mentor/dashboard' ? 'active' : ''}`} onClick={() => handleItemClick('/mentor/dashboard')}>
                            <FontAwesomeIcon icon={faHome} className="mr-3" /> Home
                        </a>
                        <a className={`menu-item ${navigate.pathname === '/mentor/chat' ? 'active' : ''}`} onClick={() => handleItemClick('/mentor/chat')}>
                            <FontAwesomeIcon icon={faUsers} className="mr-3" /> My Students
                        </a>
                        <a className={`menu-item ${navigate.pathname === '/mentor/session' ? 'active' : ''}`} onClick={() => handleItemClick('/mentor/session')}>
                            <FontAwesomeIcon icon={faCalendarAlt} className="mr-3" /> My Sessions
                        </a>
                        <a className={`menu-item ${navigate.pathname === '/mentor/content' ? 'active' : ''}`} onClick={() => handleItemClick('/mentor/content')}>
                            <FontAwesomeIcon icon={faBook} className="mr-3" /> Content Library
                        </a>
                        <a className={`menu-item ${navigate.pathname === '/mentor/forum' ? 'active' : ''}`} onClick={() => handleItemClick('/mentor/forum')}>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className="mr-3" /> View Help Forums
                        </a>
                        <a className={`menu-item ${navigate.pathname === '/mentor/settings' ? 'active' : ''}`} onClick={() => handleItemClick('/mentor/settings')}>
                            <FontAwesomeIcon icon={faCog} className="mr-3" /> Settings
                        </a>
                    </Menu>
                </div>
            </div>
            <div className="flex items-center gap-4 md:gap-6 mr-4 md:mr-6">
                <button className="custom-color text-white border-none px-4 py-2 md:px-6 md:py-4 text-xs md:text-sm font-normal rounded-md cursor-pointer custom-color:hover" onClick={handleJoinSessionClick}>
                    Join Session
                </button>
                <button className="custom-color text-white border-none px-4 py-2 md:px-6 md:py-4 text-xs md:text-sm font-normal rounded-md cursor-pointer custom-color:hover" onClick={handleAddSessionClick}>
                    Request Session
                </button>
                <div className="relative cursor-pointer" onClick={toggleNotifications}>
                    <FontAwesomeIcon icon={faBell} className="text-base md:text-lg" style={{ color: 'var(--primary-color)' }} />
                    {unreadCount > 0 && <span className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 rounded-full"></span>}
                    {notificationsVisible && <Notifications userId={instructorData?._id} />}
                </div>
                <div className="flex items-center cursor-pointer relative" onClick={toggleDropdown}>
                    {instructorData && <img src={instructorData.profilePicture || defaultProfileImage} alt="Profile" className="w-8 h-8 md:w-10 md:h-10 rounded-full mr-2 md:mr-2.5" />}
                    <span className="text-xs md:text-sm">▼</span>
                    {dropdownVisible && (
                        <div className="absolute top-16 right-0 bg-white shadow-lg rounded-md w-64 text-sm z-[7000]">
                            <div className="flex items-center p-4">
                                {instructorData && <img src={instructorData.profilePicture || defaultProfileImage} alt="Profile" className="w-12 h-12 md:w-16 md:h-16 rounded-full mr-3" />}
                                <p className="font-bold">{instructorData ? `${instructorData.firstName} ${instructorData.lastName}` : 'Loading...'}</p>
                            </div>
                            <hr className="m-0 border-t border-border-color" />
                            <ul className="list-none p-0 m-0">
                                <li className="p-4 flex items-center cursor-pointer hover:bg-gray-100" onClick={() => navigate('/mentor/settings')}>
                                    <FontAwesomeIcon icon={faUser} className="mr-3" /> Edit Profile
                                </li>
                                <li className="p-4 flex items-center cursor-pointer hover:bg-gray-100" onClick={handleLogout}>
                                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-3" /> Logout
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            {isModalOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
                    onClick={closeModal}
                >
                    <div
                    className="bg-white rounded-lg w-full max-w-lg max-h-[90vh] p-4 md:p-6 shadow-lg relative overflow-hidden"
                    onClick={(e) => e.stopPropagation()}
                    >
                    <div className="flex justify-between items-center text-base md:text-lg font-bold mb-4">
                        Request New Session
                        <button
                        className="text-base md:text-lg cursor-pointer hover:text-blue-500"
                        onClick={closeModal}
                        >
                        <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div className="overflow-y-auto max-h-[75vh]">
                        {/* Pass closeModal as a prop */}
                        <InstructorSessionForm instructorId={instructorData?._id} closeModal={closeModal} />
                    </div>
                    </div>
                </div>
            )}

            {isJoinModalOpen && (
                <UpcomingSessionsModal closeModal={closeModal} />
            )}
        </header>
    );
};

export default Header;
