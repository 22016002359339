import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import styles from './CreateChallenge.module.css';
import api from './../../utils/auth';
import Swal from 'sweetalert2';

const CreateChallenge = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [host, setHost] = useState(null);
  const [rewards, setRewards] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [checkpointOption, setCheckpointOption] = useState('Daily');
  const [checkpoints, setCheckpoints] = useState([]);
  const [currentCheckpoint, setCurrentCheckpoint] = useState({ title: '', quillContent: '', index: -1, file: null });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [rewardInput, setRewardInput] = useState('');
  const [minStartDate, setMinStartDate] = useState('');
  const [image, setImage] = useState(null);

  // Ref for Quill Editor
  const quillRef = useRef(null);

  // Fetch instructors on component mount
  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await api.get('/admin/instructors');
        const instructorOptions = response.data.map((instructor) => ({
          value: instructor._id,
          label: `${instructor.firstName} ${instructor.lastName}`,
        }));
        setInstructors(instructorOptions);
      } catch (error) {
        console.error('Error fetching instructors:', error);
        Swal.fire('Error', 'Failed to load instructors.', 'error');
      }
    };

    fetchInstructors();
  }, []);

  // Set minimum selectable start date to 5 days from today
  useEffect(() => {
    const today = new Date();
    const minStart = new Date(today.setDate(today.getDate()));
    setMinStartDate(minStart.toISOString().split('T')[0]);
  }, []);

  const handleRewardInputChange = (e) => {
    setRewardInput(e.target.value);
  };

  const handleAddReward = () => {
    if (rewards.length >= 3) {
      Swal.fire('Error', 'You cannot add more than 3 rewards.', 'error');
      return;
    }

    if (rewardInput.trim() && !rewards.includes(rewardInput.trim())) {
      setRewards([...rewards, rewardInput.trim()]);  // Trim the reward input before adding
      setRewardInput('');
    }
  };
  
  const handleRemoveReward = (reward) => {
    setRewards(rewards.filter(r => r !== reward));
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddReward();
    }
  };

  // Handle start date changes
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  // Handle end date changes
  const handleEndDateChange = (e) => {
    const selectedEndDate = new Date(e.target.value);
    const selectedStartDate = new Date(startDate);

    if ((selectedEndDate - selectedStartDate) / (1000 * 3600 * 24) < 3) {
      Swal.fire('Error', 'The challenge must last at least 3 days.', 'error');
      setEndDate('');
    } else {
      setEndDate(e.target.value);
    }
  };

  // Open modal for editing a specific checkpoint
  const openCheckpointModal = (index) => {
    const checkpoint = checkpoints[index] || { title: '', quillContent: '', index: index, file: null };
    setCurrentCheckpoint(checkpoint);
    setIsModalOpen(true);
  };

  // Handle file change for the checkpoint
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 50 * 1024 * 1024) { // Check for file size (50MB limit)
      setCurrentCheckpoint({ ...currentCheckpoint, file });
    } else {
      Swal.fire('Error', 'File size must be less than 50MB.', 'error');
    }
  };

  // Save the current checkpoint and close modal
  const saveCheckpoint = () => {
    const updatedCheckpoints = [...checkpoints];
    if (currentCheckpoint.index >= 0) {
      updatedCheckpoints[currentCheckpoint.index] = currentCheckpoint;
    } else {
      updatedCheckpoints.push(currentCheckpoint);
    }
    setCheckpoints(updatedCheckpoints);
    setCurrentCheckpoint({ title: '', quillContent: '', index: -1, file: null });
    setIsModalOpen(false);
  };

  // Generate checkpoints based on start date, end date, and option
  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const totalDays = (end - start) / (1000 * 3600 * 24);
      const checkpointCount = checkpointOption === 'Daily' ? totalDays : Math.ceil(totalDays / 7);

      const newCheckpoints = Array.from({ length: checkpointCount }, (_, i) => ({
        title: `${checkpointOption === 'Weekly' ? 'Week' : 'Day'} ${i + 1}`,
        quillContent: '',
        index: i,
        file: null
      }));
      setCheckpoints(newCheckpoints);
    }
  }, [startDate, endDate, checkpointOption]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!host) {
      Swal.fire('Error', 'Please select a host.', 'error');
      return;
    }
  
    if (!image) {
      Swal.fire('Error', 'Please upload an image.', 'error');
      return;
    }
  
    setLoading(true);
  
    try {
      // Upload the image directly to S3 using the presigned URL
      let imageUrl = await uploadFile(image);
  
      if (!imageUrl) {
        throw new Error('Image upload failed.');
      }
  
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('host', host.value);
      formData.append('rewards', JSON.stringify(rewards));
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('checkpointOption', checkpointOption);
      formData.append('image', imageUrl);
  
      // Upload checkpoint files and collect their URLs
      const updatedCheckpoints = await Promise.all(
        checkpoints.map(async (checkpoint) => {
          if (checkpoint.file) {
            const fileUrl = await uploadFile(checkpoint.file);
            if (fileUrl) {
              return { ...checkpoint, fileUrl };
            }
          }
          return checkpoint;
        })
      );
  
      // Stringify checkpoints data for backend JSON parsing
      formData.append(
        'checkpointsData',
        JSON.stringify(
          updatedCheckpoints.map(({ title, quillContent, fileUrl, file }) => ({
            title,
            quillContent,
            fileUrl, // Include the uploaded file URL
            fileName: file ? file.name : '', // Include the file name if it exists
            fileSize: file ? file.size : 0, // Include the file size if it exists
          }))
        )
      );
  
      // Make API request to create the challenge
      const response = await api.post('/admin/challenges', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 201) {
        Swal.fire('Success!', 'Challenge created successfully!', 'success');
        // Reset form fields if needed
      } else {
        Swal.fire('Error!', response.data.message || 'Failed to create challenge.', 'error');
      }
    } catch (error) {
      Swal.fire('Error!', error.message || 'An error occurred while creating the challenge.', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  
  

  // New function to handle file upload using presigned URLs
  const uploadFile = async (file) => {
    if (file) {
      try {
        // Request a presigned URL from your server
        const response = await api.post('/admin/generatePresignedUrl', {
          fileName: file.name,
          fileType: file.type,
        });
  
        if (response.status !== 200) {
          Swal.fire('Error', 'Failed to get presigned URL.', 'error');
          return;
        }
  
        const { uploadUrl } = response.data;
  
        // Upload the file directly to S3 using the presigned URL
        const uploadResponse = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        });
  
        if (uploadResponse.ok) {
          return uploadUrl.split('?')[0]; // Return the S3 file URL without the query parameters
        } else {
          Swal.fire('Error', 'File upload failed.', 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'An error occurred during file upload.', 'error');
        console.error('Error uploading file:', error);
      }
    }
  };
  
  

  // New function to handle batch uploads
  const uploadFilesInBatches = async (checkpoints) => {
    const batchSize = 5; // Adjust this number based on your server's capability
    for (let i = 0; i < checkpoints.length; i += batchSize) {
      const batch = checkpoints.slice(i, i + batchSize);
      await Promise.all(batch.map(checkpoint => uploadFile(checkpoint.file)));
    }
  };

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean']  // remove formatting button
    ]
  };

  return (
    <div className={styles.createChallengeContainer}>
      <h2 className={styles.createChallengeHeader}>Create a New Challenge</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label>Challenge Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className={styles.inputField}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Cover Image</label>
          <div className={styles.coverImageContainer}>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Host</label>
          <Select
            value={host}
            onChange={setHost}
            options={instructors}
            placeholder="Select host"
            isSearchable
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label>Rewards</label>
          <div className={styles.rewardsInputContainer}>
            <input
              type="text"
              value={rewardInput}
              onChange={handleRewardInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Add a reward and press Enter"
              className={styles.inputField}
            />
            <button type="button" onClick={handleAddReward} className={styles.addButton}>Add</button>
          </div>
          <div className={styles.rewardsList}>
            {rewards.map((reward, index) => (
              <div key={index} className={styles.rewardTag}>
                {reward}
                <span onClick={() => handleRemoveReward(reward)} className={styles.removeTag}>×</span>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Challenge Description</label>
          <ReactQuill
            ref={quillRef}
            value={description}
            onChange={setDescription}
            modules={quillModules}
            className={`${styles.textareaField} ${styles.richTextEditor}`}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            min={minStartDate}
            required
            className={styles.inputField}
          />
        </div>

        <div className={styles.formGroup}>
          <label>End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            required
            className={styles.inputField}
          />
        </div>

        <div className={styles.checkpointSection}>
          <h3 className={styles.checkpointHeader}>Checkpoints</h3>
          <div className={styles.checkpointsScrollable}>
            {checkpoints.map((checkpoint, index) => (
              <div
                key={index}
                className={styles.checkpointItem}
                onClick={() => openCheckpointModal(index)}
              >
                {checkpoint.title}
              </div>
            ))}
          </div>
        </div>

        <button type="submit" className={styles.submitButton} disabled={loading}>
          {loading ? 'Creating...' : 'Create Challenge'}
        </button>
      </form>

      {/* Checkpoint Modal */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Edit Checkpoint"
          className={styles.modalContent}
          overlayClassName={styles.modalOverlay}
        >
          <h2>Edit Checkpoint</h2>
          <div className={styles.formGroup}>
            <label>Checkpoint Title</label>
            <input
              type="text"
              value={currentCheckpoint?.title || ''}
              onChange={(e) => setCurrentCheckpoint({ ...currentCheckpoint, title: e.target.value })}
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Checkpoint Description</label>
            <ReactQuill
              ref={quillRef}
              value={currentCheckpoint?.quillContent || ''}
              onChange={(value) => setCurrentCheckpoint({ ...currentCheckpoint, quillContent: value })}
              modules={quillModules}
              className={styles.richTextEditor}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Upload File</label>
            <input
              type="file"
              onChange={handleFileChange}
              className={styles.inputField}
            />
            {currentCheckpoint.file && (
              <p>Selected file: {currentCheckpoint.file.name}</p>
            )}
          </div>
          <button onClick={saveCheckpoint} className={styles.submitButton}>
            Save Checkpoint
          </button>
        </Modal>
      )}
    </div>
  );
};

export default CreateChallenge;
