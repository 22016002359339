// utils/csrf.js
// utils/csrf.js
import { BASE_URL } from "../Constants";

export const fetchCsrfToken = async () => {
  try {
    const response = await fetch(`${BASE_URL}/csrf-token`, { // Correct usage of template literals
      credentials: 'include',
    });
    

    if (!response.ok) {
      throw new Error('Failed to fetch CSRF token');
    }

    const data = await response.json();
    return data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    throw error;
  }
};
