import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import api from '../../utils/auth';
import ChallengeDetailsModal from './ChallengeDetailsModal';
import Swal from 'sweetalert2';
import { FaTrophy } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import useUserStore from '../../store/useStudentStore';

const NewChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const { userData } = useUserStore();


  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingEnroll, setLoadingEnroll] = useState(false); // Loading state for enroll button
  const [isPremium, setIsPremium] = useState(false); // Add isPremium state
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await api.get('/students/profile');
        setTimezone(response.data.timezone);
        setIsPremium(response.data.isPremium); // Store isPremium status
      } catch (error) {
        console.error('Error fetching student profile:', error);
      }
    };

    fetchStudentData();
  }, []);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await api.get('/student-challenges/challenges');
        const challenges = response.data;

        const activeChallenges = challenges.filter(
          (challenge) => new Date(challenge.endDate) >= new Date() && !challenge.isEnrolled
        );
        setChallenges(activeChallenges);
      } catch (error) {
        console.error('Error fetching challenges:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChallenges();
  }, []);

  const handleEnroll = async (challengeId) => {
    if (userData.enrollChallenge === 0) {
      Swal.fire({
        title: 'Upgrade Required',
        text: 'Upgrade your plan to Pro to enroll in challenges.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary-color)',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upgrade Now',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/student/payment'); // Navigate to the upgrade page
        }
      });
    } else if (userData.enrollChallenge !== 0) {
      setLoadingEnroll(true); // Show loader when enrolling
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to enroll in this challenge?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'var(--primary-color)',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, enroll me!',
          cancelButtonText: 'Cancel',
        });
  
        if (result.isConfirmed) {
          const response = await api.post('/student-challenges/challenges/enroll', { challengeId });
  
          if (response.status === 201) {
            Swal.fire('Enrolled!', 'You have been successfully enrolled in the challenge.', 'success');
  
            // Refresh challenges list after successful enrollment
            const updatedChallenges = await api.get('/student-challenges/challenges');
            const activeChallenges = updatedChallenges.data.filter(
              (challenge) => new Date(challenge.endDate) >= new Date() && !challenge.isEnrolled
            );
            setChallenges(activeChallenges);
  
          } else if (response.status === 403) {
            const errorMsg = response.data.msg || '';
  
            if (errorMsg === 'You have already availed your enrollment of challenge for this month.') {
              Swal.fire('Enrollment Limit Reached', 'You have already availed your enrollment of challenge for this month.', 'info');
            } else if (errorMsg === 'Upgrade your account to enroll in challenges.') {
              Swal.fire({
                title: 'Upgrade Required',
                text: 'Upgrade your account to enroll in challenges.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: 'var(--primary-color)',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Upgrade Now',
                cancelButtonText: 'Cancel',
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate('/upgrade'); // Navigate to the upgrade page
                }
              });
            } else {
              Swal.fire('Access Denied', 'You do not have permission to enroll in this challenge.', 'info');
            }
          }
        }
      } catch (error) {
        console.error('Error enrolling in challenge:', error);
  
        // Enhanced error logging
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
  
          Swal.fire('Error', `${error.response.data.msg || 'An error occurred.'}`, 'error');
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Request data:', error.request);
  
          Swal.fire('Error', 'No response received from the server. Please try again later.', 'error');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message);
  
          Swal.fire('Error', 'An error occurred while setting up the request. Please try again later.', 'error');
        }
      } finally {
        setLoadingEnroll(false); // Hide loader after completion
      }
    }
  };


  const handleViewMore = (challenge) => {
    setSelectedChallenge(challenge);
    setShowModal(true);
  };

  const formatTimeUntilStart = (startDate) => {
    if (!timezone) {
      return ''; // Wait until timezone is set
    }

    const start = moment.tz(startDate, timezone);
    const now = moment.tz(timezone);
    const duration = moment.duration(start.diff(now));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (duration.asMilliseconds() <= 0) {
      return 'Already Started';
    } else {
      return `Starts in ${days}d ${hours}h ${minutes}m`;
    }
  };

  if (loading) {
    return (
      <div className="max-w-[1400px] mx-auto">
        <div className="flex flex-wrap gap-4">
          {[...Array(2)].map((_, index) => (
            <div
              key={index}
              className="bg-white rounded-lg p-2.5 shadow-md w-full sm:w-[calc(50%-1rem)] lg:w-[calc(25%-1rem)] relative flex flex-col justify-between min-h-[300px]"
            >
              <Skeleton height={150} className="rounded-lg" />
              <div className="mt-2.5 flex-grow flex flex-col">
                <Skeleton width={`80%`} height={20} />
                <div className="text-[13px] mt-2">
                  <Skeleton width={`60%`} height={15} />
                  <Skeleton width={`50%`} height={15} />
                  <Skeleton width={`70%`} height={15} />
                  <Skeleton count={3} height={15} />
                </div>
              </div>
              <div className="mt-2 flex justify-between items-center gap-2.5">
                <Skeleton width={`48%`} height={35} />
                <Skeleton width={`48%`} height={35} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[1400px] mx-auto">
      <div className="flex flex-wrap gap-4">
        {challenges.map((challenge) => (
          <div
            key={challenge._id}
            className="bg-white rounded-lg p-2.5 shadow-md w-full sm:w-[calc(50%-1rem)] lg:w-[calc(25%-1rem)] relative flex flex-col justify-between min-h-[300px]"
          >
            <img src={challenge.image} alt={challenge.title} className="w-full h-[150px] rounded-lg object-cover" />
            <div className="mt-2.5 flex-grow flex flex-col">
              <h3 className="text-base font-semibold">{challenge.title}</h3>
              <div className="text-[13px] mt-2">
                <p className="text-primary-color mb-1">
                  {moment.tz(challenge.startDate, timezone).format('L')} -{' '}
                  {moment.tz(challenge.endDate, timezone).format('L')}
                </p>
                <p className="text-gray-700 mb-1">{formatTimeUntilStart(challenge.startDate)}</p>
                <p className="text-gray-700 mb-1">Checkpoints: {challenge.checkpoints.length}</p>
                <p className="text-gray-700 mb-1">Rewards:</p>
                <ul className="list-none p-0 m-0 text-gray-700">
                  {challenge.rewards.map((reward, index) => (
                    <li key={index} className="flex items-center mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                      <FaTrophy className="mr-1 text-[#ffd700]" />
                      <span>{reward}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-2 flex justify-between items-center gap-2.5">
              <button
                onClick={() => handleEnroll(challenge._id)}
                className="bg-primary-color text-white py-2 px-4 rounded-md cursor-pointer flex-grow text-center hover:opacity-80"
                disabled={loadingEnroll} // Disable button while loading
                style={{ width: '100px', height: '40px' }} // Maintain consistent button dimensions
              >
                {loadingEnroll ? (
                  <div className="loader" style={{ width: '20px', height: '20px' }}></div> // Show loader
                ) : (
                  "Enroll"
                )}
              </button>
              <button
                onClick={() => handleViewMore(challenge)}
                className="bg-gray-300 text-black py-2 px-4 rounded-md cursor-pointer flex-grow text-center hover:opacity-80"
              >
                View More
              </button>
            </div>
          </div>
        ))}
      </div>
      {showModal && selectedChallenge && (
        <ChallengeDetailsModal challenge={selectedChallenge} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default NewChallenges;
