import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useInstructorStore from '../../store/useInstructorStore';
import api from '../../utils/auth';

const allSkills = [
  "Transportation", "Education", "Engineering", "Healthcare", "Business", "Finance", "Marketing", "Sales",
  "Services", "Public Services", "Repair", "Maintenance", "Aviation", "Accountancy", "Insurance", "Food and Beverage",
  "Hospitality", "Tourism", "Recreation", "Information Technology", "Management", "Software Development", "Human Resources",
  "Legal", "Social Work", "Art and Design", "Communications", "Construction", "Environmental", "Science", "Technology",
  "Pharmaceuticals", "Real Estate", "Retail", "Security", "Logistics", "Manufacturing", "Nonprofit", "Project Management",
  "Research", "Sports", "Digital Marketing", "Data Analysis", "Cybersecurity", "Machine Learning", "Event Management",
  "Customer Service", "Graphic Design", "Consulting", "Entrepreneurship", "Agricultural Science", "Biotechnology", "Blockchain",
  "Cloud Computing", "Compliance", "Corporate Strategy", "Creative Writing", "Crisis Management", "E-commerce", "Educational Technology",
  "Elder Care", "Electrical Engineering", "Energy Sector", "Fashion Design", "Film Production", "Game Development", "Government Relations",
  "Health Informatics", "Humanitarian Aid", "Interior Design", "International Business", "Journalism", "Labor Relations",
  "Leadership Development", "Market Research", "Materials Science", "Mental Health", "Mobile App Development", "Nanotechnology",
  "Network Security", "Occupational Therapy", "Patent Law", "Performance Arts", "Petroleum Engineering", "Philanthropy",
  "Photography", "Physical Therapy", "Political Science", "Public Health", "Quality Assurance", "Renewable Energy", "Risk Management",
  "Robotics", "Social Media Management", "Supply Chain Management", "Urban Planning", "Veterinary Medicine", "Video Production",
  "Virtual Reality", "Web Design", "Immigration"
];

const EditSkills = () => {
  const { instructorData, setInstructorData } = useInstructorStore(); // Fetch from Zustand
  const [searchTerm, setSearchTerm] = useState('');
  const [addedSkills, setAddedSkills] = useState(instructorData.addedSkills || []);
  const [filteredSkills, setFilteredSkills] = useState(allSkills);
  const [recommendedSkills, setRecommendedSkills] = useState(instructorData.recommendedSkills || []);
  const [originalRecommendedSkills, setOriginalRecommendedSkills] = useState(instructorData.recommendedSkills || []);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCV, setCurrentCV] = useState(instructorData.cv || null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dropdownRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFilteredSkills(allSkills.filter(skill => skill.toLowerCase().includes(value.toLowerCase())));
    setDropdownVisible(true);
  };

  const handleAddSkill = (skill) => {
    if (addedSkills.length < 10 && !addedSkills.includes(skill)) {
      setAddedSkills([...addedSkills, skill]);
      setRecommendedSkills(recommendedSkills.filter(s => s !== skill));
      setSearchTerm('');
      setDropdownVisible(false);
    }
  };

  const handleRemoveSkill = (skill) => {
    setAddedSkills(addedSkills.filter(s => s !== skill));
    if (originalRecommendedSkills.includes(skill) && !recommendedSkills.includes(skill)) {
      setRecommendedSkills([...recommendedSkills, skill]);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const handleDownloadCV = () => {
    if (currentCV) {
      const link = document.createElement('a');
      link.href = `${currentCV}`;
      link.download = currentCV.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleUploadCV = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('cv', file);

    setIsLoading(true);
    try {
      const response = await api.post(`/instructors/upload-cv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      const uploadedCV = response.data.cv;
      setCurrentCV(uploadedCV);

      const rescanResponse = await api.put(`/instructors/rescan-cv`, {}, {

      });
      setRecommendedSkills(rescanResponse.data.recommendedSkills);
      setOriginalRecommendedSkills(rescanResponse.data.recommendedSkills);

      // Update Zustand Store
      setInstructorData({
        ...instructorData,
        cv: uploadedCV,
        recommendedSkills: rescanResponse.data.recommendedSkills,
      });
    } catch (error) {
      console.error('Error uploading CV or rescanning:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    setLoadingSubmit(true); 
    try {
      await api.put(`/instructors/profile`, { addedSkills }, {
      });

      // Update Zustand Store with new skills
      setInstructorData({
        ...instructorData,
        addedSkills,
      });

      swal("Success", "Skills updated successfully!", "success");
    } catch (error) {
      console.error('Error updating skills:', error);
    } finally {
      setLoadingSubmit(false); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setDropdownVisible(true);
    if (searchTerm === '') {
      setFilteredSkills(allSkills);
    }
  };

  return (
    <div className="max-w-[700px] p-5">
      <div className="text-2xl font-bold">
        {isDataLoading ? <Skeleton width={200} /> : "Add your skills"}
      </div>
      {isDataLoading ? (
        <Skeleton count={2} height={20} style={{ marginTop: '10px', marginBottom: '10px' }} />
      ) : (
        <p className="text-sm text-gray-600 mb-2">Skills help students know what you specialize in.</p>
      )}
      <div className="mt-[24px] relative w-full" ref={dropdownRef}>
        {isDataLoading ? (
          <Skeleton height={40} />
        ) : (
          <input
            type="text"
            placeholder="Ex. Search for a skill like Business Management"
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={handleFocus}
            className="w-full p-[10px] border border-[#ccc] rounded-[8px]"
          />
        )}
        {isDropdownVisible && !isDataLoading && (
          <div className="absolute top-full left-0 w-full border border-t-0 border-[#ccc] rounded-b-[5px] max-h-[150px] overflow-y-auto bg-white z-[1000]">
            {filteredSkills.map(skill => (
              <div key={skill} className="p-[10px] cursor-pointer hover:bg-[#f0f0f0]" onClick={() => handleAddSkill(skill)}>
                {skill}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-wrap mt-[16px] mb-[20px]">
        {addedSkills.map(skill => (
          <div key={skill} className="p-[8px_12px] rounded-[20px] cursor-pointer m-[4px] text-[var(--primary-color)] border border-[var(--primary-color)] bg-[#e0f7fa]" onClick={() => handleRemoveSkill(skill)}>
            {skill}
          </div>
        ))}
      </div>
      {addedSkills.length >= 10 && !isDataLoading && (
        <p className="text-red-500 mt-[10px]">You can only add up to 10 skills.</p>
      )}
      <div className="mt-[20px] bg-[var(--secondary-background-color)] p-[20px] rounded-[16px] border-[1.5px] border-[var(--black-overlay)]">
        <h3 className="mb-[10px]">
          {isDataLoading ? <Skeleton width={150} /> : "Recommended Skills"}
        </h3>
        <div className="flex flex-wrap gap-[10px]">
          {isLoading || isDataLoading ? (
            <Skeleton count={3} height={30} width={50} />
          ) : recommendedSkills.length > 0 ? (
            recommendedSkills.map(skill => (
              <div key={skill} className="bg-[#f0f0f0] p-[8px_12px] rounded-[20px] cursor-pointer text-[var(--primary-color)] border border-[var(--primary-color)]" onClick={() => handleAddSkill(skill)}>
                + {skill}
              </div>
            ))
          ) : (
            <p>No recommended skills available.</p>
          )}
        </div>
      </div>

      <div className="mt-[20px]">
        <div className='font-bold text-xl'>Your Resume/CV</div>
        <div className='text-gray-600 text-sm'>
          Update or view your current CV.
        </div>
        <>
          <button onClick={handleDownloadCV} className="mt-[24px] py-[8px] px-[12px] mr-[10px] border border-[#17a2b8] rounded-[5px] bg-white text-[var(--primary-color)] cursor-pointer hover:bg-[var(--primary-color)] hover:text-white md:px-6 md:py-4">
            Download Current CV
          </button>
          <input 
            type="file" 
            ref={fileInputRef} 
            name="cv" 
            style={{ display: 'none' }} 
            onChange={handleUploadCV} 
          />
          <button onClick={() => fileInputRef.current.click()} className="mt-[24px] py-[8px] px-[12px] mr-[10px] border border-[#17a2b8] rounded-[5px] bg-white text-[var(--primary-color)] cursor-pointer hover:bg-[var(--primary-color)] hover:text-white md:px-6 md:py-4">
            Upload New CV
          </button>
        </>
      </div>

      <button
        onClick={handleSaveChanges}
        className="mt-[20px] py-[12px] px-[20px] border-none rounded-md bg-primary-color text-white text-lg cursor-pointer float-right hover:opacity-80"
        style={{ height: 'auto', width: 'auto' }}
        disabled={loadingSubmit} 
      >
        <div className="flex justify-center items-center">
          {loadingSubmit ? (
            <div className="loader" style={{ width: '24px', height: '24px' }}></div>
          ) : (
            "Save Changes"
          )}
        </div>
      </button>
    </div>
  );
};

export default EditSkills;
