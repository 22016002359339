import React, { useState } from 'react'; 
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import InstructorDashboard from './pages/Instructors/InstructorsDashboard/InstructorsDashboard';
import InstructorSettingsLayout from './layouts/InstructorSettingsLayout/InstructorSettingsLayout';
import SignIn from './pages/SignIn/SignIn';
import StudentDashboard from './pages/Students/StudentsDashboard/StudentsDashboard';
import ChooseAccountType from './pages/Onboarding/ChooseAccountType';
import StudentSignUp from './pages/Onboarding/StudentSignUp';
import StudentAuthenticate from './pages/Onboarding/StudentAuthenticate';
import InstructorAuthenticate from './pages/Onboarding/InstructorAuthenticate';
import InstructorSignUp from './pages/Onboarding/InstructorSignUp';
import VerifyEmail from './pages/Onboarding/VerifyEmail';
import VerifyInstructorEmail from './pages/Onboarding/VerifyInstructorEmail';
import StudentForgotPassword from './pages/SignIn/StudentForgotPassword';
import StudentResetPassword from './pages/SignIn/ResetPage';
import InstructorForgotPassword from './pages/SignIn/InstructorForgotPassword';
import InstructorScreenLayout from './layouts/InstructorDashboardLayout/InstructorScreenLayout';
import InstructorProfileView from './pages/Instructors/InstructorsSettings/InstructorProfileView';
import InstructorSession from './pages/Instructors/InstructorSession/InstructorSession';
import InstructorForum from './pages/Instructors/InstructorForum/InstructorForum';
import AdminPage from './pages/Admin/AdminPage';
import AdminSignIn from './components/Admin/AdminSignIn';
import InstructorSettings from './pages/Instructors/InstructorsSettings/InstructorSettings';
import BrowseSessions from './pages/Students/BrowseSessions/BrowseSessions';
import MyStudents from './pages/Instructors/MyStudents/MyStudents';
import MyBookings from './pages/Students/MyBookings/MyBookings';
import VideoChat from './pages/Videochat/VideoChatGeneral';
import Challenges from './pages/Students/Challenges/Challenges';
import VideoChatOneonOne from './components/VideoChat/OneonOneVideochat';
import ContentLibraryInstructor from './pages/Instructors/Content Library/ContentLibrary';
import ContentLibraryStudent from './pages/Students/Content Library/ContentLibrary';
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoutes';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MyProfile from './components/InstructorSettings/MyProfile';
import EditSkills from './components/InstructorSettings/EditSkills';
import Experience from './components/InstructorSettings/Experience';
import Education from './components/InstructorSettings/InstructorEducation';
import Achievement from './components/InstructorSettings/InstructorAchievement';
import OneOnOne from './components/InstructorSettings/OneOnOne';
import Services from './components/InstructorSettings/Services';
import Notification from './components/InstructorSettings/Notification';
import Billing from './components/InstructorSettings/Billing';
import StudentScreenLayout from './layouts/StudentDashboardLayout/StudentScreenLayout';
import ComingSoon from './pages/Students/Job Portal/ComingSoon'; // Import the ComingSoon component
import BrowseMentors from './components/StudentBrowseMentors/BrowseMentors';
import SubscriptionPayment from './components/SubscriptionPayment';
import SessionPaymentPage from './components/StudentBrowseMentors/SessionPaymentPage';
import StudentInstructorProfileView from './components/StudentBrowseMentors/InstructorProfileView';
import ServicePaymentPage from './components/StudentBrowseMentors/ServicePaymentPage';
import CVClinic from './components/StudentCVClinic';
import StudentForum from './pages/Students/StudentForum/StudentForum';
import StudentSettings from './pages/Students/StudentsSettings/StudentSettings';
import StudentProfile from './components/StudentSettings/MyProfile';
import StudentSkills from './components/StudentSettings/StudentSkills';
import StudentIndustry from './components/StudentSettings/StudentIndustry';
import MyInstructors from './pages/Students/MyInstructors/MyInstructors';
import StudentEducation from './components/StudentSettings/StudentEducation';
import StudentNotification from './components/StudentSettings/Notifications';
import StudentBilling from './components/StudentSettings/Billing';
import VideoChatMessage from './components/VideoChat/VideoChatMessage';
import EnterPhone from './components/EnterPhone/EnterPhone';
import './App.css';
import './index.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const InstructorScreenLayoutWrapper = () => (
  <InstructorScreenLayout>
    <Outlet />
  </InstructorScreenLayout>
);

const StudentScreenLayoutWrapper = () => (
  <StudentScreenLayout>
    <Outlet />
  </StudentScreenLayout>
);

function App() {
  const [selectedOption, setSelectedOption] = useState('My Profile');

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ChooseAccountType />} />
        <Route path="/admin" element={<AdminSignIn />} />
        <Route path="/enter-phone" element={<EnterPhone />} />
        <Route path="/admin/dashboard" element={<AdminPage />} />
        <Route path="/student/signup" element={<StudentSignUp />} />
        <Route path="/mentor/signup" element={<InstructorSignUp />} />
        <Route path="/student/authentication" element={<StudentAuthenticate />} />
        <Route path="/mentor/authentication" element={<InstructorAuthenticate />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/verify-mentor-email" element={<VerifyInstructorEmail />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/student/forgot-password" element={<StudentForgotPassword />} />
        <Route path="/mentor/forgot-password" element={<InstructorForgotPassword />} />
        <Route path="/reset/:token" element={<StudentResetPassword />} />
        <Route path="/general/:sessionId" element={<VideoChat />} />
        <Route path="/one-on-one/:sessionId" element={<VideoChatOneonOne />} />
        <Route path="/video-chat/:token" element={<VideoChatMessage />} />

        <Route element={<ProtectedRoute roles={['instructor']} />}>
          <Route element={<InstructorScreenLayoutWrapper />}>
            <Route path="/mentor/dashboard" element={<InstructorDashboard />} />
            <Route path="/mentor/chat" element={<MyStudents />} />
            <Route path="/mentor/settings" element={<InstructorSettings />} />
            <Route path="/mentor/view-profile" element={<InstructorProfileView />} />
            <Route path="/mentor/session" element={<InstructorSession />} />
            <Route path="/mentor/content" element={<ContentLibraryInstructor />} />
            <Route path="/mentor/forum" element={<InstructorForum />} />
            <Route path="/mentor/settings" element={<InstructorSettingsLayout />}>
              <Route path="profile" element={<MyProfile />} />
              <Route path="skills" element={<EditSkills />} />
              <Route path="experience" element={<Experience />} />
              <Route path="education" element={<Education />} />
              <Route path="achievements" element={<Achievement />} />
              <Route path="1-on-1" element={<OneOnOne />} />
              <Route path="services" element={<Services />} />
              <Route path="notifications" element={<Notification />} />
              <Route path="billing" element={<Billing />} />
            </Route>
          </Route>
        </Route>

        <Route element={<ProtectedRoute roles={['student']} />}>
          <Route path="/student/payment" element={
            <Elements stripe={stripePromise}>
              <SubscriptionPayment />
            </Elements>
          } />
          <Route element={<StudentScreenLayoutWrapper />}>
            <Route path="/student/session-payment" element={
              <Elements stripe={stripePromise}>
                <SessionPaymentPage />
              </Elements>
            } />
            <Route path="/student/service-payment" element={
              <Elements stripe={stripePromise}>
                <ServicePaymentPage />
              </Elements>
            } />
            <Route path="/student/dashboard" element={<StudentDashboard />} />
            <Route path="/student/chat" element={<MyInstructors />} />
            <Route path="/student/browse-mentors" element={<BrowseMentors />} />
            <Route path="/job-portal" element={<ComingSoon />} /> {/* Define the route */}
            <Route path="/student/browse-sessions" element={<BrowseSessions />} />
            <Route path="/student/content" element={<ContentLibraryStudent />} />

            <Route path="/student/browse-mentors/view-profile/:id" element={<StudentInstructorProfileView />} />
            <Route path="/CVClinic" element={<CVClinic />} />
            <Route path="/student/challenges" element={<Challenges />} />
            <Route path="/student/forum" element={<StudentForum />} />
            <Route path="/student/my-bookings" element={<MyBookings />} />
            <Route path="/student/settings" element={
              <StudentSettings selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
            }>
              <Route path="profile" element={<StudentProfile />} />
              <Route path="skills" element={<StudentSkills />} />
              <Route path="education" element={<StudentEducation />} />
              <Route path="industry-preferences" element={<StudentIndustry />} />
              <Route path="notification" element={<StudentNotification />} />
              <Route path="billing" element={<StudentBilling />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
